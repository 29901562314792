import React, { Component } from 'react'
import { Link } from 'gatsby'
import styles from './banner.module.sass'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

var url = typeof window !== 'undefined' ? window.location.href : ''

class Banner extends Component {
   render() {
      return (
         <section
            className={[
               styles.section__banner,
               !!this.props.small ? styles.small : '',
            ].join(' ')}>
            <div className={styles.inner}>
               {!!this.props.icon && (
                  <div className={styles.icon}>
                     <FontAwesomeIcon icon={this.props.icon} />
                  </div>
               )}
               <p className={styles.text}>
                  <b>{this.props.title}</b> {this.props.text}
               </p>
               {!!this.props.actionText && !!this.props.actionTo && (
                  <div className={styles.action}>
                     <a
                        href={this.props.actionTo}
                        rel="noopener noreferrer"
                        target={this.props.target ? this.props.target : '_self'}
                        className="button button--primary button--rounded button--shadow button--has-icon">
                        {this.props.actionText} <span>&rarr;</span>
                     </a>
                  </div>
               )}
               {!!this.props.questionLink && !url.includes('events') && (
                  <div className={styles.action}>
                     <Link
                        to="/events"
                        className="button button--primary button--link button--large button--has-icon">
                        MORE INFO <span>&rarr;</span>
                     </Link>
                  </div>
               )}
               {!!this.props.questionLink && url.includes('events') && (
                  <div
                     className={styles.action}
                     dangerouslySetInnerHTML={{
                        __html: `
                        <a
                           href="#"
                           onClick="LC_API.open_chat_window();return false;"
                           class="button button--primary button--link button--large button--has-icon">
                           ASK QUESTIONS <span>&rarr;</span>
                        </a>
                     `,
                     }}></div>
               )}
            </div>
         </section>
      )
   }
}

export default Banner
