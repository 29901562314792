import React from 'react'
import { Link } from 'gatsby'
import styles from './dashboard.module.sass'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
   faLongArrowAltRight,
   faBriefcase,
} from '@fortawesome/free-solid-svg-icons'

import Banner from '../components/banner'

import Layout from '../components/layout'
import SEO from '../components/seo'

const CompanyPage = () => (
   <Layout>
      <SEO title="Our Company" />
      <div className={['page', styles.page__dashboard].join(' ')}>
         <div className={['page--header', styles.page__header].join(' ')}>
            <h1 className={['page--title', styles.page__title].join(' ')}>
               Our Company
            </h1>
            <p
               className={['page--subheader', styles.page__subheader].join(
                  ' '
               )}>
               At SPC Financial<sup>&reg;</sup>, we provide simple solutions to
               complex financial problems on a human level.
            </p>
         </div>
         <div className={styles.dashboard__sections}>
            <div className={styles.section}>
               <div className={styles.section__content}>
                  <h3 className={styles.section__title}>About Us</h3>
                  <p className={styles.section__description}>
                     Learn about who we are, where we originated, and what we
                     can do <b>for you</b>.
                  </p>
               </div>
               <div className={styles.section__actions}>
                  <Link
                     to="/about"
                     className="button button--secondary button--rounded button--has-icon">
                     Discover Us <FontAwesomeIcon icon={faLongArrowAltRight} />
                  </Link>
               </div>
            </div>
            <div className={styles.section}>
               <div className={styles.section__content}>
                  <h3 className={styles.section__title}>Our Team</h3>
                  <p className={styles.section__description}>
                     Familiarize yourself with our award-winning team of
                     tax-integrated financial advisors.
                     <Link to="/legal/disclaimers#tax">*</Link>
                  </p>
               </div>
               <div className={styles.section__actions}>
                  <Link
                     to="/team"
                     className="button button--primary button--rounded button--has-icon">
                     Let's Meet <FontAwesomeIcon icon={faLongArrowAltRight} />
                  </Link>
               </div>
            </div>
            <div className={styles.section}>
               <div className={styles.section__content}>
                  <h3 className={styles.section__title}>Our Services</h3>
                  <p className={styles.section__description}>
                     We are proud to offer a wide array of white-glove services
                     to our loyal clients.
                  </p>
               </div>
               <div className={styles.section__actions}>
                  <Link
                     to="/services"
                     className="button button--secondary button--rounded button--has-icon">
                     Be Our Guest <FontAwesomeIcon icon={faLongArrowAltRight} />
                  </Link>
               </div>
            </div>
            <div className={styles.section}>
               <div className={styles.section__content}>
                  <h3 className={styles.section__title}>
                     Rankings + Recognition
                  </h3>
                  <p className={styles.section__description}>
                     Please take a moment to review some of the rankings and
                     recognition we've acquired over the years.
                  </p>
               </div>
               <div className={styles.section__actions}>
                  <Link
                     to="/rankings"
                     className="button button--primary button--rounded button--has-icon">
                     View Our Showcase{' '}
                     <FontAwesomeIcon icon={faLongArrowAltRight} />
                  </Link>
               </div>
            </div>
         </div>
         <div>
            <Banner
               icon={faBriefcase}
               title="Careers"
               text="Interested in joining our human-centric financial team?"
               actionText="View Opportunities"
               actionTo="/careers"
            />
         </div>
      </div>
   </Layout>
)

export default CompanyPage
